import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "rentalDisplay",
	standalone: true,
})
export class RentalDisplayPipe implements PipeTransform {
	transform(rentalIdentifier: string, rentalKey: string): string {
		return rentalKey ? `${rentalIdentifier}/${rentalKey}` : rentalIdentifier;
	}
}
