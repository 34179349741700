{
	"name": "@e-tenant-hub/source",
	"version": "3.2.1",
	"license": "MIT",
	"scripts": {
		"start": "npx nx run-many --target=serve --all=true",
		"start:client-platform": "npx nx run client-platform:serve",
		"start:pay-rent-now": "npx nx run pay-rent-now:serve",
		"start:client-platform:staging": "npx nx run client-platform:serve:staging",
		"build:client-platform:release": "npx nx build client-platform --configuration=production",
		"build:pay-rent-now:release": "npx nx build pay-rent-now --configuration=production",
		"lint": "nx affected:lint",
		"format": "nx format:write",
		"format:check": "nx format:check",
		"install:localhost-ssl": "powershell -NoProfile -ExecutionPolicy Unrestricted -Command ./scripts/set-localhost-ssl-certificate.ps1",
		"prepare": "husky"
	},
	"private": true,
	"dependencies": {
		"@angular/animations": "18.1.1",
		"@angular/cdk": "18.1.1",
		"@angular/common": "18.1.1",
		"@angular/compiler": "18.1.1",
		"@angular/core": "18.1.1",
		"@angular/forms": "18.1.1",
		"@angular/material": "18.1.1",
		"@angular/platform-browser": "18.1.1",
		"@angular/platform-browser-dynamic": "18.1.1",
		"@angular/router": "18.1.1",
		"@angular/service-worker": "18.1.1",
		"@maskito/angular": "^3.0.0",
		"@maskito/core": "^3.0.0",
		"@maskito/kit": "^3.0.0",
		"@microsoft/signalr": "^8.0.7",
		"@nx/angular": "19.5.1",
		"angular-oauth2-oidc": "^17.0.2",
		"angular-oauth2-oidc-jwks": "^17.0.2",
		"dayjs": "^1.11.12",
		"file-saver": "^2.0.5",
		"libphonenumber-js": "^1.11.4",
		"ngx-cookie-service": "^18.0.0",
		"ngx-webstorage": "^18.0.0",
		"rxjs": "~7.8.0",
		"swiper": "^11.1.5",
		"tslib": "^2.3.0",
		"zone.js": "0.14.4"
	},
	"devDependencies": {
		"@angular-devkit/build-angular": "18.1.1",
		"@angular-devkit/core": "18.1.1",
		"@angular-devkit/schematics": "18.1.1",
		"@angular-eslint/eslint-plugin": "18.0.1",
		"@angular-eslint/eslint-plugin-template": "18.0.1",
		"@angular-eslint/template-parser": "18.0.1",
		"@angular/cli": "~18.1.0",
		"@angular/compiler-cli": "18.1.1",
		"@angular/language-service": "18.1.1",
		"@nx/eslint": "19.5.1",
		"@nx/eslint-plugin": "19.5.1",
		"@nx/jest": "19.5.1",
		"@nx/js": "19.5.1",
		"@nx/workspace": "19.5.1",
		"@schematics/angular": "18.1.1",
		"@swc-node/register": "1.9.2",
		"@swc/core": "1.5.7",
		"@swc/helpers": "0.5.11",
		"@types/file-saver": "^2.0.7",
		"@types/jest": "^29.4.0",
		"@types/node": "^18.16.9",
		"@typescript-eslint/eslint-plugin": "7.16.0",
		"@typescript-eslint/parser": "7.16.0",
		"@typescript-eslint/utils": "7.16.0",
		"autoprefixer": "^10.4.19",
		"eslint": "8.57.0",
		"eslint-config-prettier": "^9.0.0",
		"husky": "^9.1.1",
		"jest": "^29.4.1",
		"jest-environment-jsdom": "^29.4.1",
		"jest-preset-angular": "14.1.1",
		"jsonc-eslint-parser": "^2.1.0",
		"lint-staged": "^15.2.7",
		"nx": "19.5.1",
		"postcss": "^8.4.39",
		"prettier": "3.3.3",
		"prettier-plugin-organize-attributes": "^1.0.0",
		"prettier-plugin-organize-imports": "^4.0.0",
		"prettier-plugin-tailwindcss": "^0.6.5",
		"tailwind-scrollbar": "^3.1.0",
		"tailwindcss": "^3.4.6",
		"ts-jest": "^29.1.0",
		"ts-node": "10.9.1",
		"typescript": "5.5.3"
	},
	"volta": {
		"node": "20.15.1"
	}
}
